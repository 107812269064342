<template>
  <div class="pageview">
    <!-- banner切换 -->
    <van-swipe class="homebanner"
      :autoplay="3000"
      indicator-color="white">
      <van-swipe-item><img :src="require('../../assets/partymember/党建1.jpg')" /></van-swipe-item>
      <van-swipe-item><img :src="require('../../assets/partymember/dangjian.jpg')" /></van-swipe-item>
      <van-swipe-item><img :src="require('../../assets/partymember/etuanjian.jpg')" /></van-swipe-item>
    </van-swipe>

    <van-grid class="wktype"
      :border="false">
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/partymember/history')">
        <van-image class="iconimg"
          :src="require('../../assets/partymember/党史教育.png')"></van-image>
        <span class="wktext">党史教育</span>
      </van-grid-item>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/partymember/consult')">
        <van-image class="iconimg"
          :src="require('../../assets/partymember/党务资讯.png')"></van-image>
        <span class="wktext">党务资讯</span>
      </van-grid-item>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/partymember/activity')">
        <van-image class="iconimg"
          :src="require('../../assets/partymember/党建活动.png')"></van-image>
        <span class="wktext">党建活动</span>
      </van-grid-item>
      <van-grid-item icon="photo-o"
        @click="gotoUrl('/partymember/integral')">
        <van-image class="iconimg"
          :src="require('../../assets/partymember/积分排行.png')"></van-image>
        <span class="wktext">积分排行</span>
      </van-grid-item>
    </van-grid>

    <div class="djlink">
      <a href="https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB"
        referrerpolicy="no-referrer"> <img :src="require('../../assets/partymember/党建2.jpg')"
          width="100%"
          alt=""> </a>

    </div>

    <div class="contentmsg">
      <div class="contentA">
        <!-- <img :src="require('../../assets/partymember/content.png')" /> -->
        <span class="grouptitle"
          style="border-left: 4px solid rgba(237, 102, 78, 1);line-height: 20px;font-size: 16px">通知公告</span>
        <!-- <span class="blue">公告</span> -->
        <!-- <div @click="gotoUrl('/partymember/message')"
          class="newsmore">查看全部</div> -->
      </div>
      <div class="information">
        <div class="center"
          v-for="item in List"
          :key="item.index">
          <div @click="gotoUrl('/partymember/message/' + item.RIId)">

            <div class="Content">
              <div style="display: inline-block;width: 33%;"
                class="ContentA"
                ref="getHeight">
                <img v-if="item.ThematicUrl"
                  :src="item.ThematicUrl"
                  alt="" />
              </div>
              <div style="display: inline-block;width: 63%;"
                class="ContentB">
                <div class="centerTitle van-ellipsis--l2">{{ item.Title }}</div>
              </div>
              <span class="centertime"
                style="float: right;margin-top: -15px;">{{ item.IssTime }}</span>
            </div>
          </div>
          <!-- <div style="border:1px solid red;"></div> -->
        </div>

        <van-empty description="暂无通知"
          v-if="!List.length" />
      </div>
      <!-- <div class="information">
        <div class="center"
          v-for="item in List"
          :key="item.index">
          <div @click="gotoUrl('/partymember/message/' + item.RIId)">
            <div class="Content">
              <div style="display: inline-block;width: 42%;"
                class="ContentA">
                <img v-if="item.ThematicUrl"
                  :src="item.ThematicUrl"
                  alt="" />
              </div>
              <div style="display: inline-block;width: 55%;"
                class="ContentB">

                <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
                <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                <br v-if="item.Summary == ''" />
                <br v-if="item.Summary == ''" />
                <span class="centertime">{{ item.IssTime }}</span>
              </div>
            </div>
          </div>
        </div>
        <van-empty description="暂无通知"
          v-if="!List.length" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { WeGetPartyInfoPage } from "@/api/RealInfo";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  // computed: {
  //   ...mapGetters([
  //     'openId',
  //   ])
  // },
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data () {
    return {
      List: [],
    };
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
    this.getPartyInfoList();
  },
  methods: {
    //跳转到公共页面
    gotoUrl (path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 通知消息
    getPartyInfoList () {
      WeGetPartyInfoPage({ TKId: 15, page: 1, limit: 5 }).then((res) => {
        this.List = res.data.data;
        for (var i = 0; i < this.List.length; i++) {
          var item = this.List[i].IssTime;
          item = this.dataFormat(item);
          this.List[i].IssTime = item;
          if (this.List[i].ThematicUrl == "") {
            this.List[i].ThematicUrl =
              "http://lccgzapi.zhsq.cloud//upload/images/2021/11/b6b3ab86-df3.png";
          }
        }
      });
    },
    // 党务
    dangwu: function () {
      var url = 'https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB'
      window.localtion.href = url
      // window.open('https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB');
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
};
</script>

<style scoped>
.djlink {
  margin-top: 1.5vh;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  font-size: 0;
}
.contentA {
  width: 100%;
  overflow: hidden;
  line-height: 30px;
  padding: 1vh 1.5vh;
  box-sizing: border-box;
}
.contentA img {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
}
.contentA span {
  float: left;
  font-size: 18px;
  color: #333333;
  font-weight: 700;
  line-height: 30px;
}
.contentA .newsmore {
  float: right;
  color: #777;
  font-size: 14px;
  line-height: 30px;
}
.contentA .blue {
  color: #477fff;
}
.pageview {
  background: #f6f6f6;
  min-height: 100%;
  padding: 1vh;
  box-sizing: border-box;
}
.homebanner img {
  width: 100%;
  height: 27vh;
  vertical-align: top;
}
.wktype {
  padding: 0;
  margin-top: 1.5vh;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.contentmsg {
  background: #fff;
  margin-top: 1.5vh;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px;
}
</style>